<template>
    <div>
        <Nav/>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>
                <span v-if="token && !invalidToken">
                    Reset Password
                </span>
                <span v-else>
                    Lost Your Password?
                </span>
            </h1>

            <div v-show="feedback" v-html="feedback" class="margin_bottom"></div>

            <div v-show="validationFeedback" class="margin_bottom text-danger">
                {{ validationFeedback }}
            </div>

            <div v-if="token && !invalidToken">
                <div v-show="!hidePasswordDiv">
                    <label>Set New Password</label>

                    <form @submit.prevent="reset" class="form-inline">
                        <input v-model="password" type="password" class="form-control">
                        <input type="submit" value="Set Password" class="btn btn-default">
                    </form>
                </div>
            </div>
            <div v-else>
                <label>A link to reset your password will be email to</label>

                <form @submit.prevent="sendEmail" class="form-inline">
                    <div class="form-group">
                        <input v-model="email" type="text" class="form-control" placeholder="your@email.com">
                    </div>
                    <div class="form-group">
                        <input type="submit" value="Send Email" class="btn btn-default">
                    </div>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
    import { myStore } from "@/store/My.store";
    import Nav from "@/components/my/MyNav";

    export default {
        name: "PasswordReset",
        components: {
            Nav
        },
        data() {
            return {
                feedback: null,
                validationFeedback: null,
                email: null,
                password: null,
                hidePasswordDiv: false,
                invalidToken: false
            }
        },
        methods: {
            reset: function () {
                if (!this.validatePassword()) {
                    this.validationFeedback = 'Password must be at least 8 characters long';

                    return;
                } else {
                    this.validationFeedback = null;
                }

                let params = {
                    'params[passwd_new]': this.password,
                    'params[token]': this.token,
                    'params[user_id]': this.userID,
                };

                myStore.api('myuser/reset_password_from_token', params).then(data => {
                    if (typeof data !== "undefined") {
                        this.hidePasswordDiv = true;
                        this.feedback = data.msg;
                    }
                });
            },
            sendEmail: function () {
                if (!this.validateEmail()) {
                    this.validationFeedback = 'Invalid Email';

                    return;
                } else {
                    this.validationFeedback = null;
                }

                let params = {
                    'params': this.email
                };

                myStore.api('myuser/reset_password_request', params).then(data => {
                    if (typeof data !== "undefined") {
                        this.feedback = data.msg;
                    }
                });
            },
            validateEmail: function () {
                return this.$appStore.validateEmail(
                    this.email
                );
            },
            validatePassword: function () {
                if (!this.password) {
                    return false;
                }

                return this.password.length >= 8;
            }
        },
        created() {
            if (this.isLive) {
                this.$router.push(`/`);
            }

            if (this.token && this.userID) {
                let params = {
                    'params[token]': this.token,
                    'params[user_id]': this.userID,
                };

                myStore.api('myuser/is_valid_password_token_json', params).then(isValid => {
                    if (!isValid) {
                        this.feedback = 'Your password reset token has expired. Please make a new request.';
                        this.invalidToken = true;
                    }
                });
            }
        },
        computed: {
            token: function () {
                return this.$route.query.token;
            },
            userID: function () {
                return this.$route.query.user_id;
            },
            isLive: function () {
                return this.$appStore.state.loggedIn;
            }
        }
    }
</script>

<style scoped>

</style>